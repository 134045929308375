<template>
  <div>
    <gmap-custom-marker :marker="{ lat, lng }" alignment="center" :offset-x="-48" :offset-y="-48">
      <div class="DepartureMarker">
        <div class="DepartureMarker__center"></div>
      </div>
    </gmap-custom-marker>
  </div>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';

export default {
  name: 'DepartureMarker',
  components: {
    'gmap-custom-marker': GmapCustomMarker,
  },
  props: {
    lat: {
      type: Number,
      required: true,
    },
    lng: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.DepartureMarker {
  position: absolute;
  display: block;
}
.DepartureMarker__center {
  width: 12px;
  height: 12px;
  position: relative;
  top: 38px;
  left: 38px;
  background: #212a72;
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: 1000;
}
</style>
