<template>
  <Map v-if="bounds.length > 0" ref="map" :bounds="bounds">
    <VehicleMarker v-if="markers.vehicle" :lat="markers.vehicle.lat" :lng="markers.vehicle.long" />
    <DepartureMarker
      v-if="markers.departure"
      :lat="markers.departure.lat"
      :lng="markers.departure.long"
    />
    <CustomerMarker
      v-if="markers.customer"
      :lat="markers.customer.lat"
      :lng="markers.customer.long"
    />
  </Map>
</template>

<script>
import Map from './components/Map';
import VehicleMarker from './components/VehicleMarker';
import CustomerMarker from './components/CustomerMarker';
import DepartureMarker from './components/DepartureMarker';

export default {
  name: 'DeliveryMap',
  components: {
    VehicleMarker,
    CustomerMarker,
    DepartureMarker,
    Map,
  },
  props: {
    markers: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      bounds: null,
    };
  },
  computed: {},
  watch: {
    markers: {
      handler(markers) {
        this.bounds = Object.values(markers).reduce(function(markersList, marker) {
          if (marker && marker.lat && marker.long) {
            markersList.push({ lat: marker.lat, lng: marker.long });
          }
          return markersList;
        }, []);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
