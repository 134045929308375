<template>
  <div>
    <gmap-custom-marker :marker="{ lat, lng }" alignment="center" :offset-x="-48" :offset-y="-48">
      <div class="CustomerMarker">
        <div class="CustomerMarker__center"></div>
      </div>
    </gmap-custom-marker>
  </div>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';

export default {
  name: 'CustomerMarker',
  components: {
    'gmap-custom-marker': GmapCustomMarker,
  },
  props: {
    lat: {
      type: Number,
      required: true,
    },
    lng: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.CustomerMarker {
  position: absolute;
  display: block;
}
.CustomerMarker__center {
  width: 20px;
  height: 20px;
  position: relative;
  top: 38px;
  left: 38px;
  background: rgba(5, 124, 255, 1);
  border: 2px solid #fff;
  border-radius: 50%;
  z-index: 1000;
}
</style>
