<template>
  <GmapMap
    ref="map"
    :center="center"
    :zoom="7"
    :options="combinedOptions"
    class="Map"
    @tilesloaded="mapLoaded"
  >
    <slot name="default" />
  </GmapMap>
</template>

<script>
import { options as defaultOptions } from './options';

import Vue from 'vue';
import * as GmapVue from 'gmap-vue';
import { gmapApi } from 'gmap-vue';

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyAlKbIV2-i5I0fnenyds1pKgxfBOIhLgjI',
  },
  installComponents: true,
});

export default {
  name: 'Map',
  components: {},
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    center: {
      type: Object,
      default() {
        return {
          lat: 56,
          lng: 10.5,
        };
      },
    },
    bounds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      mapReady: false,
    };
  },
  computed: {
    combinedOptions() {
      return { ...defaultOptions, ...this.options };
    },
    google: gmapApi,
  },
  watch: {
    bounds: {
      handler(bounds) {
        this.updateBounds();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.$gmapApiPromiseLazy();

    if (this.bounds) {
      this.updateBounds();
    }
  },
  methods: {
    mapLoaded() {
      this.mapReady = true;
    },
    updateBounds() {
      if (!this.bounds) {
        return;
      }
      if (this.bounds.length === 1) {
        // Centers map if only 1 bound
        this.$refs.map.panTo(new this.google.maps.LatLng(this.bounds[0].lat, this.bounds[0].lng));
      } else {
        // fitBounds if multiply bounds
        const bounds = new this.google.maps.LatLngBounds();
        this.bounds.forEach(bound => {
          bounds.extend(new this.google.maps.LatLng(bound.lat, bound.lng));
        });
        this.$refs.map.fitBounds(bounds);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Map {
  display: block;
  min-width: 200px;
  min-height: 200px;
  width: 100%;
  height: 100%;
}
</style>
