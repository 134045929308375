<template>
  <div>
    <gmap-custom-marker :marker="{ lat, lng }" alignment="center" :offset-x="-15" :offset-y="-15">
      <div class="VehicleMarker">
        <div class="VehicleMarker__center">
          <div class="VehicleMarker__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="truck"
              class="svg-inline--fa fa-truck fa-w-20"
              role="img"
              viewBox="0 0 640 512"
            >
              <path
                fill="white"
                d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"
              />
            </svg>
          </div>
        </div>
        <div class="VehicleMarker__ripple"></div>
      </div>
    </gmap-custom-marker>
  </div>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';

export default {
  name: 'VehicleMarker',
  components: {
    'gmap-custom-marker': GmapCustomMarker,
  },
  props: {
    lat: {
      type: Number,
      required: true,
    },
    lng: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
@keyframes ripple {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.VehicleMarker {
  position: absolute;
  display: block;
}
.VehicleMarker__icon {
  margin: 3px;
  position: relative;
  top: 2px;
}

.VehicleMarker__center {
  width: 30px;
  height: 30px;
  position: relative;
  background: #212a72;
  border: 2px solid #212a72;
  border-radius: 50%;
  z-index: 1000;
}

.VehicleMarker__ripple {
  top: -33px;
  right: 3px;
  width: 36px;
  height: 36px;
  position: relative;
  background: #212a72;
  border-radius: 50%;
  opacity: 0;
  animation: ripple 2400ms ease-out infinite;
}
</style>
